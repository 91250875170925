"use strict";
export enum EnumParent {
  General,
  KeepParent,
  Linked
}

export enum enumscope {
  global = 0,
  local = 1
}

export enum tdfModules {
  NONE = 0,
  TDF_BSI = 1,
  TDF_InfoCenter = 3,
  TDF_Mailer = 7,
  TDF_SalesPlanning = 11,
  TDF_ContractManagement = 13,
  TDF_EventManagement = 15,
  TDF_Mobile = 17,
  TDF_PhoneLogging = 19,
  TDF_ExchangeServices = 21,
  TDF_WebIntegrationAPI = 22,
  TDF_EmailLoggingService = 23,
  TDF_OfflineCapability = 24,
  TDF_BYOR = 26,
  TDF_AdvancedAnalytics = 28,
  TDF_Alerts = 29,
  TDF_DraftEditor = 30,
  TDF_GapAnalysis = 31,
  TDF_GoogleSyncService = 32,
  TDF_InboxJanitorMailboxSync = 33,
  TDF_IncentivesTracking = 34,
  TDF_MobileSyncService = 35,
  TDF_Reminders = 36,
  TDF_TemplateManagement = 37,
  TDF_WorkFlowManagement = 38,
  TDF_AccountManagement = 39,
  TDF_ActivityManagement = 40,
  TDF_AppointmentManagement = 41,
  TDF_AssetManagement = 42,
  TDF_ContactManagement = 43,
  TDF_DocumentManagement = 44,
  TDF_EmailManagement = 45,
  TDF_ExpenseManagement = 46,
  TDF_ForecastManagement = 47,
  TDF_KnowledgeBaseManagement = 48,
  TDF_LiteratureManagement = 49,
  TDF_NoteManagement = 50,
  TDF_OpportunityManagement = 51,
  TDF_QuoteManagement = 52,
  TDF_Support_CaseManagement = 53,
  TDF_TaskManagement = 54,
  TDF_TicketManagement = 55,
  TDF_BSIVendor = 56,
  TDF_MailingManagement = 57,
  TDF_MarketingIntegration = 58,
  TDF_ConversationManagement = 59,
  TDF_LeadManagement = 60,
  TDF_EnterpriseAlerts = 61,
  TDF_WordpressAddinForAPI = 62,
  TDF_TrelloIntegration = 63,
  TDF_TimeTracker = 64,
  TDF_SPAIntegration = 65,
  TDF_LeadForensicsIntegration = 66,
  TDF_Objectives = 67,
  TDF_AdvancedReporting = 68,
  CSS_CSSAdmin = 69,
  CSS_SherpaEnterprise = 70,
}

export enum tdfRights {
  rightConvertContacts = 101,
  rightTemplateManager = 102,
  rightUDFManager = 103,
  rightMoveDelete = 104,
  rightDynamicGridEditor = 105,
  rightGlobalGridManager = 106,
  rightTaskGroupManager = 107,
  rightCombineItems = 108,
  rightBSIGoalsManager = 109,
  rightBSIAccountPlanning = 110,
  rightContractsAdmin = 111,
  rightQuoteAdministration = 112,
  rightPropertyImport = 113,
  rightProductRegAdmin = 114,
  rightEventAdmin = 115,
  rightExpenseAdmin = 116,
  rightEditBSIIDs = 117,
  rightViewItemSalesHistory = 118,
  rightChangeOwner = 119,
  rightExportData = 120,
  rightOpportunityGoals = 121,
  rightBSIOptions = 122,
  rightEditTDFActiveFlag = 123,
  rightRemoveDuplicates = 124,
  rightMassUpdate = 125,
  rightGlobalDraftEditor = 126,
  rightScreenDesigner = 127,
  rightOfflineSync = 128,
  rightEditBSContactID = 129,
  rightIncentivesAdmin = 130,
  rightEditBSIMasterAccountId = 131,
  rightViewAdvancedAnalytics = 132,
  rightCompanyPreferences = 133,
  rightBSIPreferences = 134,
  rightUserPermissionWizard = 135,
  rightGridUDFDesigner = 136,
  rightLookupListMaintenance = 137,
  rightManageAlertsAndNotifications = 138,
  rightProductRegistration = 139,
  rightTourdeForceMobileAdmin = 140,
  rightUpdateLastActivity = 141,
  rightImportUtility = 142,
  rightArchiveItems = 143,
  rightAttachmentMaintenance = 144,
  rightMoveDeleteAccounts = 145,
  rightMoveDeleteContacts = 146,
  rightPurgeItems = 147,
  rightRestoreItems = 148,
  rightTaskReassign = 149,
  rightUnDeleteItems = 150,
  rightUpdateOwners = 151,
  rightAdminLogViewer = 152,
  rightUpdateRecordNextAction = 153,
  rightUpdateQuotes = 154,
  rightMakeItemKeysUnique = 155,
  rightParseContactFullNamesintoFirstandLastNames = 156,
  rightProperCaseUtility = 157,
  rightSetItemsActive = 158,
  rightUpdateFileAs = 159,
  rightUpdatePhoneNumbers = 160,
  rightUpdateSalesStage = 161,
  rightAssetAdministration = 162,
  rightManageAdvancedAnalytics = 163,
  rightExchangeSyncSettings = 164,
  rightMailboxSyncSettings = 165,
  rightSyncBSIDataOffline = 166,
  rightUserSettings = 167,
  rightAddQuoteProducts = 168,
  rightAllowSwitchDatabase = 169,
  rightServiceAdministration = 170,
  rightSupportAdmin = 171,
  rightCanCreateBYORReports = 172,
  rightCanEditAllBYORReports = 173,
  rightCreateWorkflowFromItem = 174,
  rightWorkflowAdministration = 175,
  rightAllowSendReminders = 176,
  rightEditHistoricalSalesStageData = 177,
  rightManageExternalWebUserPermissions = 178,
  rightExportLogViewer = 179,
  rightPropagateAccountInfoToContacts = 180,
  rightBulkItemCreate = 181,
  rightViewThirdPartyMarketingData = 182,
  rightExportThirdPartyMailingList = 183,
  rightThirdPartyMarketingAdmin = 184,
  rightDeleteMailings = 185,
  rightCanEditAllUserStatus = 186,
  rightAllowNewItemCustomBody = 187,
  rightLeadAdmin = 188,
  rightQuoteBOMAdmin = 189,
  rightTrelloAdmin = 190,
  rightTimeTrackerAdmin = 191,
  rightSpaAdmin = 192,
  rightViewLeadForensics = 193,
  rightViewFolderStructure = 194,
  rightViewBIPermissions = 195,
  rightVieEmailLoggingSettings = 196,
  rightAdvancedReportingDesigner = 197,
  rightEditGlobalDashboard = 198,
  rightEditPersonalDashboards = 199,
  rightWebSyncSettings = 204
}

export enum IncludeBodyEnum {
  Unknown = -1,
  None = 0,
  NotesOnly = 1,
  NotesAndDetail = 2,
  DetailsOnly = 3,
  DetailsAndNotes = 4
}

// This preference is stored differently in the database than it needs to be for the option.
export enum IncludeBodyEnumPref {
  Unknown = -1,
  None = 0,
  NotesOnly = 2,
  NotesAndDetail = 3,
  DetailsOnly = 4,
  DetailsAndNotes = 5
}

export enum CalculationMethodType {
  None = 2,
  Standard = 0,
  Forecast = 1
}

export enum EnumAddressTypes {
  Business = 1,
  Home = 2,
  Other = 3
}

export enum EnumAddressFields {
  OtherLongitude = -129,
  OtherLatitude = -128,
  HomeLongitude = -127,
  HomeLatitude = -126,
  BusinessLongitude = -125,
  BusinessLatitude = -124,
  OtherStreet3 = -123,
  OtherStreet2 = -122,
  HomeStreet3 = -121,
  HomeStreet2 = -120,
  BusinessStreet3 = -119,
  BusinessStreet2 = -118,
  OtherCounty = -117,
  OtherCountry = -116,
  OtherZip = -115,
  OtherState = -114,
  OtherCity = -113,
  OtherStreet = -112,
  HomeCounty = -111,
  HomeCountry = -110,
  HomeZip = -109,
  HomeState = -108,
  HomeCity = -107,
  HomeStreet = -106,
  BusinessCounty = -105,
  BusinessCountry = -104,
  BusinessZip = -103,
  BusinessState = -102,
  BusinessCity = -101,
  BusinessStreet = -100
}

export enum menuActions {
  Unknown = -1,
  menuNewAccount = 0,
  menuLinkAccount = 1,
  menuNewActivity = 2,
  menuNewAppointment = 3,
  menuNewContact = 4,
  menuLinkContact = 5,
  menuNewDocument = 6,
  menuNewEmail = 7,
  menuNewExpense = 8,
  menuNewNote = 9,
  menuNewOpportunity = 10,
  menuLinkOpportunity = 11,
  menuNewSupport = 12,
  menuNewTask = 13,
  menuOpenContactFromEmail = 14,
  menuTaskGroup = 16,
  menuAddContact = 17,
  menuEditSalesProcess = 18,
  menuCopyItem = 19,
  menuNewContract = 20,
  menuEditContract = 21,
  menuNewEvent = 23,
  menuEditEvent = 24,
  menuQuoteManager = 26,
  menuEditSupport = 27,
  menuEditOpportunity = 29,
  menuInviteContactToEvent = 40,
  menuOpenPublicItem = 41,
  menuOpenProfile = 42,
  menuChangeParent = 43,
  menuCopyToPersonalFolder = 44,
  menuAddToFavorites = 45,
  menuNewAsset = 46,
  menuLinkAsset = 48,
  menuNewTicket = 49,
  menuLinkTicket = 51,
  menuCreateSupportFromAsset = 52,
  menuCreateContractFromAsset = 53,
  menuOpenParent = 54,
  menuOpenAccount = 55,
  menuOpenContact = 56,
  menuOpenInNavigator = 57,
  menuNewForecast = 58,
  menuLinkForecast = 60,
  menuPrintItem = 61,
  menuLaunchMap = 62,
  menuIncentivesEnroll = 63,
  menuIncentivesRequestEnroll = 64,
  menuIncentivesRedeem = 65,
  menuSendToBSI = 66,
  menuSendToAnalysis = 67,
  menuGoToWebsite = 68,
  menuShareItem = 69,
  menuNewLiterature = 70,
  menuLinkLiterature = 72,
  menuNewQuote = 73,
  menuLinkQuote = 75,
  menuCreateQuoteFromAsset = 76,
  menuNewLead = 78,
  menuLinkLead = 80,
  menuCreateLeadFromAsset = 81,
  menuDeleteLead = 82,
  menuAttachFiles = 83,
  menuToggleTDFActive = 84,
  menuCreateLiteratureFromAsset = 85,
  menuChangeAcctPkg = 87,
  menuMarkAsDeleted = 88,
  menuNewKnowledgeBase = 89,
  menuLinkKnowledgeBase = 91,
  menuRemoveFavorite = 92,
  menuSendReminder = 93,
  menuViewTransactions = 94,
  menuNewConversation = 95,
  menuWebDataForContact = 96,
  menuCompleteAndConvert = 97,
  menuPropagateAccountInformation = 98,
  menuLeadQualify = 99,
  menuLeadClose = 100,
  menuLeadSetStage = 101,
  menuShowActionPanel = 102,
  menuSendToContactSummary = 103,
  menuViewWebAccessLog = 104,
  menuCreateQuoteDocument = 105,
  menuCreateMultipleForecasts = 106,
  menuSendToCombineItemsUtil = 107,
  menuDownloadAddressDataFromThirdParty = 108,
  menuLaunchWorkflow = 109,
  menuAddEmailAsQuickNote = 110,
  menuLinkSupport = 111,
  menuLinkContract = 112,
  menuLinkEvent = 113,
  menuLinkActivity = 114,
  menuLinkAppointment = 115,
  menuLinkDocument = 116,
  menuLinkEmail = 117,
  menuLinkNote = 118,
  menuLinkTask = 119,
  menuLinkExpense = 120,
  menuAddTimeEntry = 121,
  menuAddWorklistItem = 122,
  menuAddToAgenda = 123,
  menuRemoveFromAgenda = 124,
  menuOpenInWeb = 125,
  menuNewObjective = 126,
  menuLinkObjective = 127,
  menuNewGeneralItem = 128,
  menuLinkItem = 129,
  menuEditExpense = 130,
  menuLaunchSherpaEnterprise = 132,
  menuMassUpdate = 133
}

export enum itemTypes {
  itemUnknown = 0,
  itemAccount = 1,
  itemActivity = 2,
  itemAppointment = 3,
  itemDocument = 4,
  itemContact = 5,
  itemEmail = 6,
  itemNote = 7,
  itemOpportunity = 8,
  itemSupport = 9,
  itemTask = 10,
  itemAccountPackage = 11,
  itemExpense = 12,
  itemQuote = 13,
  itemProduct = 14,
  itemForecast = 15,
  itemMailing = 16,
  itemAffiliates = 17,
  itemContract = 18,
  itemEvent = 19,
  itemEmailArchive = 20,
  itemProductRegistration = 21,
  itemAsset = 22,
  itemQuoteLine = 23,
  itemTicket = 24,
  itemPhoneLog = 25,
  itemEventInvitation = 26,
  itemLiterature = 27,
  itemKnowledgeBase = 28,
  itemConversations = 29,
  itemLead = 30,
  itemOrderHeader = 31,
  itemOrderLine = 32,
  itemParentAccount = 50,
  itemEventInvitation_Lead = 51,
  itemOpps_w_LinkedAccts = 52,
  itemObjective = 55,
  itemAccountContact = 500,
  itemReminder = 996,
  itemRecentItem = 997,
  itemFavorite = 998,
  itemAllItems = 999,
  itemMisc = 1000,
  itemMyActionlist = 1001,
  itemAllParents = 1002,
  itemTransactions = 1003,
  itemAllChildrenOfItemParent = 1004
}

export enum EnumParentType {
  Primary,
  Secondary
}

export enum itemAccountTypes {
  Undefined = -1,
  Regular = 0,
  SalesOnly = 1,
  Master = 2,
  Vendor = 3
}

export enum tdfViews {
  NONE = 0,
  viewAccounts,
  viewActivities,
  viewAppointments,
  viewContacts,
  viewDocuments,
  viewEmail,
  viewExpenses,
  viewNotes,
  viewOpportunities,
  viewSupport,
  viewTasks,
  viewBSI,
  viewForecast,
  viewAll = 14,
  viewPhoneLogging,
  viewAffiliates,
  viewBSIPG,
  viewMailings,
  viewRegistration,
  viewQuotes,
  viewContracts,
  viewBSIVendor,
  viewEvents,
  viewSalesStageHistory,
  viewBSIData,
  viewAssets,
  viewTickets,
  viewAllChildrenOfItemParent = 28,
  viewLiterature,
  viewProducts,
  viewKnowledgeBase,
  viewProductRegistration,
  viewParentAccounts = 33,
  viewAllParents,
  viewConversations,
  viewLeads = 36,
  viewTDFOrderHeaders = 37,
  viewTDFOrderlines = 38
}

export enum TDFGridTypes {
  No_GridType = 0,
  Account_Grid = 1,
  Appointment_Grid = 2,
  Contact_Grid = 3,
  Document_Grid = 4,
  Email_Grid = 5,
  Expense_Grid = 6,
  Note_Grid = 7,
  Opportunity_Grid = 8,
  Activity_Grid = 9,
  Support_Grid = 10,
  Task_Grid = 11,
  Search_Grid = 12,
  AllItem_Grid = 13,
  Forecast_Grid = 14,
  Bsi_Grid = 15,
  PhoneLogging_Grid = 16,
  Bsi_Summary = 17,
  Key_Summary = 18,
  Mailings_Grid = 19,
  Quotes_Grid = 20,
  BsiPG_Grid = 21,
  AccountReg_Grid = 22,
  ContactReg_Grid = 23,
  OpportunityReg_Grid = 24,
  Opportunity_Summary = 25,
  Affiliates_Grid = 26,
  Contract_Grid = 27,
  Activity_Summary = 28,
  ProdReg_Grid = 29,
  SupportCredit_Grid = 30,
  BsiVendor_Grid = 31,
  Event_Grid = 32,
  AccountContact_Grid = 33,
  Asset_Grid = 34,
  QuoteLines_Grid = 35,
  Ticket_Grid = 36,
  RecentItem_Grid = 37,
  Favorite_Grid = 38,
  EventInvitation = 39,
  BSIInventory_Grid = 40,
  Literature_Grid = 41,
  Product_Grid = 42,
  KnowledgeBase_Grid = 43,
  Reminder_Grid = 44,
  VendorSummary = 45,
  ParentAccount_Grid = 46,
  MyActionListGrid = 47,
  AllParentsGrid = 48,
  TransactionsGrid = 49,
  ConversationGrid = 50,
  Lead_Grid = 51,
  AllChildrenOfItemParent_Grid = 52,
  TDFOrderHeader_Grid = 53,
  TDFOrderline_Grid = 54,
  EventInvitation_Lead = 55,
  SupportNote_Grid = 56,
  BIDrillInGrid = 57,
  OpportunitiesWithLinkedAccounts = 58,
  WorklistItems = 59
}

export enum GridContainerTypes {
  Unknown = 0,
  Account = 1,
  Contact = 2,
  Opportunity = 3,
  Search = 4,
  Expense = 5,
  Contract = 6,
  Event = 7,
  Task = 8,
  Support = 9,
  Appointment = 10,
  BSISummary = 11,
  InfoCenter = 12,
  Drilldown = 13,
  Mailer = 14,
  ExpenseWizard = 15,
  QuoteManager = 16,
  Asset = 17,
  Ticket = 18,
  Mailing = 19,
  Forecast = 20,
  Activity = 21,
  Document = 22,
  Email = 23,
  Note = 24,
  PhoneLogging = 25,
  EventInvitations = 26,
  Literature = 27,
  Product = 28,
  KnowledgeBase = 29,
  VendorSummary = 30,
  JobSummary = 31,
  BIGroupSummaryDrilldown = 32,
  VendorGroupSummaryDrilldown = 33,
  JobGroupSummaryDrilldown = 34,
  Lead = 35,
  ContactSummary = 36,
  ContactGroupSummaryDrilldown = 37,
  TDFOrderHeader = 38,
  BIDetailFormDialogs = 39,
  Workflow = 40,
  BIDrillIn = 57,
  AdminCenter = 90,
  PreferenceCenter = 91
}

export enum ActionDisplayOptions {
  None = 0,
  Breadcrumbs = 1,
  FormMenu = 2,
  Grid = 4,
  Everywhere = 7
}

export enum EnumUDFFormat {
  udfCombination = 19,
  udfDate = 5,
  udfCurrency = 14,
  udfDuration = 7,
  udfFormula = 18,
  udfKeywords = 11,
  udfNumber = 3,
  udfPercent = 4,
  udfText = 1,
  udfYesNo = 6,
  udfPercent2 = 12
}

export enum PropertFormatCurrencyEnum {
  propDefault = 0, //<Description("Default")> [Default] = 0
  propTwoDecimals = 1, //<Description("$12,345.12 ($12,345.12)")> TwoDecimals = 1
  propNoDecimals = 2, //<Description("$12,346 ($12,346)")> NoDecimals = 2
  propThreeDecimals = 3, //<Description("$12,345.123 ($12,345.123)")> ThreeDecimals = 3
  propFourDecimals = 4 //<Description("$12,345.1234 ($12,345.1234)")> FourDecimals = 4
}

export enum PropertyFormatNumberEnum {
  propDefault = 0, //<Description("Default") > [Default] = 0
  propAllDigits = 1, //< Description("All digits: 1,234.567 -1,234.567") > AllDigits = 1
  propTruncated = 2, //< Description("Truncated: 1,235 -1,235") > Truncated = 2
  propOneDecimal = 3, //< Description("1 Decimal: 1.234.6 -1,234.6") > OneDecimal = 3
  propTwoDecimal = 4, //< Description("2 Decimal: 1.234.57 -1,234.57") > TwoDecimal = 4
  propThreeDecimal = 5, //< Description("3 Decimal: 1,234.567 -1,234.567") > ThreeDecimal = 5
  propComputer = 6, //< Description("Computer: 64 K 128 M 1 G") > Computer = 6
  propTimespan = 7 //< Description("Timespan: 2 days, 3 hours, 17 minutes") > Timespan = 7
}

export enum PropertyFormatPercentEnum {
  propDefault = 0, //< Description("Default") > [Default] = 0
  propRounded = 1, //< Description("Rounded: 65% -65%") > Rounded = 1
  propOneDecimals = 2, //< Description("1 Decimal: 65.4% -65.4%") > OneDecimals = 2
  propTwoDecimals = 3, //< Description("2 Decimal: 65.43% -65.43%") > TwoDecimals = 3
  propAllDigits = 4 //< Description("All digits: 65.4321%") > AllDigits = 4
}

export enum CenterType {
  InfoCenter,
  Bi,
  AccountBi,
  OppBi,
  ContactBi,
  VendorBi,
  GenericGroupSummary,
  GapAnalysis,
  InventorySummary,
  General,
  QuoteManager,
  EventSessionManager,
  EventInvitation,
  EventDrillDown,
  LinkedItems,
  Admin = 990,
  Preference = 991,
  Recent = 997,
  Favorite = 998
}
